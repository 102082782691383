import { useAuth0 } from "@auth0/auth0-react";
import React, { Suspense, useEffect } from "react";

import { Route, Routes, useLocation } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/AuthenticationGuard";

// Lazy imports
import HomePage from "./pages/Home";
const AccesibilityPage = React.lazy(() =>
  import("./pages/Legal/AccesibilityPage")
);
const TermsOfUsePage = React.lazy(() => import("./pages/Legal/TermsOfUsePage"));
const PrivacyPolicy = React.lazy(() => import("./pages/Legal/PrivacyPolicy"));
const CataloguePage = React.lazy(() => import("./pages/Catalogue"));

const MouldingsPage = React.lazy(() => import("./pages/Mouldings"));
const CasingPage = React.lazy(() => import("./pages/Casing"));
const BaseboardPage = React.lazy(() => import("./pages/Baseboard"));
const HeaderPage = React.lazy(() => import("./pages/Header"));
const UrbanSeries = React.lazy(() => import("./pages/UrbanSeries"));

const MouldingDatabasePage = React.lazy(() =>
  import("./pages/MouldingDatabase")
);

const CornicePage = React.lazy(() => import("./pages/Cornice"));
const DoorsPage = React.lazy(() => import("./pages/Doors"));
const Calculator = React.lazy(() => import("./pages/Calculator"));
const Dealers = React.lazy(() => import("./pages/Dealers"));

const Admin = React.lazy(() => import("./pages/Admin"));
const AboutPage = React.lazy(() => import("./pages/AboutUs"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));
const CallbackPage = React.lazy(() => import("./pages/CallbackPage"));

export const App = () => {
  const { isLoading } = useAuth0();
  const location = useLocation();
  useEffect(() => {
    import("react-ga4").then((ReactGA) => {
      ReactGA.default.initialize("G-FVMJ34FFBR");
      ReactGA.default.send("pageview");
    });
  }, []);

  useEffect(() => {
    import("react-ga4").then((ReactGA) => {
      ReactGA.default.send({ hitType: "pageview", page: location.pathname });
    });
  }, [location]);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <div>
      <Suspense fallback={<PageLoader />}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />

          <Route path="/accesibility" element={<AccesibilityPage />} />
          <Route path="/terms" element={<TermsOfUsePage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/catalogue" element={<CataloguePage />} />

          <Route path="/mouldings" element={<MouldingsPage />} />
          <Route path="/casing" element={<CasingPage />} />
          <Route path="/baseboard" element={<BaseboardPage />} />
          <Route path="/header" element={<HeaderPage />} />
          <Route path="/urban" element={<UrbanSeries />} />

          <Route path="/cornice" element={<CornicePage />} />
          <Route path="/doors" element={<DoorsPage />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/dealers" element={<Dealers />} />

          <Route path="/database" element={<MouldingDatabasePage />} />

          <Route
            path="/admin/*"
            element={<AuthenticationGuard component={Admin} />}
          />
          <Route path="/callback" element={<CallbackPage />} />
          <Route path="/loader" element={<PageLoader />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </div>
  );
};
