import React, { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { NavBarLink } from "./NavBarLink";
import { LogoutButton } from "../buttons/LogOutButton";
import { LoginButton } from "../buttons/LoginButton";
import { NavLink } from "react-router-dom";

export const NavBarLinks = () => {
  const { isAuthenticated } = useAuth0();

  const publicMenu = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "/about",
      label: "About Us",
    },
    { path: "/catalogue", label: "Catalogue" },
    {
      path: "/mouldings",
      label: "Mouldings",
      // submenu: [
      //   { path: "/baseboard", label: "Baseboard" },
      //   { path: "/casing", label: "Casing" },
      //   { path: "/cornice", label: "Cornice and Crown" },
      //   { path: "/door_jamb", label: "Door Jambs" },
      //   { path: "/header", label: "Header" },
      //   { path: "/urban", label: "Urban Series" },
      // ],
    },
    // { path: "/database", label: "Database" },
    { path: "/doors", label: "Doors" },
    {
      path: "/dealers",
      label: "Dealers",
    },
  ];
  return (
    <Fragment>
      {publicMenu.map(
        (link, i) => (
          // currentURL !== link.path && (
          <NavBarLink
            key={i}
            path={link.path}
            label={link.label}
            submenu={link.submenu && link.submenu}
          />
        )
        // )
      )}
      {!isAuthenticated && (
        <>
          <LoginButton />
        </>
      )}

      {isAuthenticated && (
        <>
          <NavLink
            to={"/admin"}
            end
            className={({ isActive }) =>
              `block truncate  py-2 pb-2 text-center font-main text-xl font-bold uppercase duration-100 ease-in last:mr-0  hover:text-secondary-100  md:py-4 md:pb-2 md:text-3xl  2xl:text-right ${
                !isActive ? `text-gray-700` : `cursor-auto text-secondary-100`
              }`
            }
          >
            Admin
          </NavLink>
          <LogoutButton />
        </>
      )}
    </Fragment>
  );
};
